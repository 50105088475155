// Initial state
(function() {
    var scrollPos = 0;
    var Header = document.getElementById('header');
    var Hamburger = document.getElementsByClassName('header--hamburger');
    
    window.addEventListener('scroll', function() {
      
      if ((document.body.getBoundingClientRect()).top > scrollPos) {
          
            Header.classList.add('__js_up');
            if(Header.classList.contains('__js_isActive')) {
                Header.classList.remove('__js_isActive');
            }
            if(Hamburger.classList !== 'undefined') {    
            } else {
                Hamburger.classList.remove('__js_isActive');
            }
            
      } else {
            if(Header.classList.contains('__js_up')) {
                Header.classList.remove('__js_up');
            }
        }
        scrollPos = (document.body.getBoundingClientRect()).top;
    });
})(); 