
//preload
var mobileViewport = window.matchMedia("screen and (max-width: 920px)"),
    srcLink= 'src';

mobileViewport.addListener(function(mq) {

    if(mq.matches) {
        srcLink = 'src-mobile';
    }
    else {
        srcLink = 'src';
    }

});

if(mobileViewport.matches) {
    srcLink = 'src-mobile';
}
else {
    srcLink = 'src';
}

var preload = function () {

        var preloadCheck = function () {

            $('.__js_preload').each(function (n) {

                var srcS;

                if($(this).isOnScreen()) {

                    if ( $(this).data('src-mobile') ){
                        srcS = $(this).data(srcLink);

                        $(this).css('opacity', '.4').delay(100).css('content', 'url(' + srcS + ')').animate({opacity: 1},450);
                        $(this).removeClass('__js_preload').attr('src', srcS);
                    }
                    else {
                        srcS = $(this).data('src');
                        $(this).css('opacity', '.3').attr('src', srcS).animate({opacity: 1},450);
                        $(this).removeClass('__js_preload');
                    };

                }

            });
            setTimeout(preloadCheck, 250);
        };
     setTimeout(preloadCheck, 500);
 };
if($('.__js_preload').length){
    preload();
}
