
// menu
"use strict";

function selectText(element) {

    var doc = document, 
        text = doc.getElementById(element), 
        //text = doc.getElementsByClassName(element),
        range, 
        selection
    ;    
    if (doc.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(text);
        range.select();
    } else if (window.getSelection) {
        selection = window.getSelection();        
        range = document.createRange();
        range.selectNodeContents(text);
        selection.removeAllRanges();
        selection.addRange(range);
    }
    document.execCommand('copy');
}

$(document).on('click', '[data-category="link"]', function(n){
    
    var $this = $(n.target);
    console.log(n.target.getAttribute('data-href') + ', type:' +  typeof(n) + ', this: ' + $this );
    var index = $this.parents('.__js_load_wrapper').index();
    console.log('index = ' + index);
    
    if (!$this.find('.__js_socials__input').length) { 
            
            $('<div class="__js_socials__input" id="__js_input' + index + '">' + $this.data('href') + '</div>').appendTo($this);
            selectText('__js_input' + index);
            $this.find('.__js_socials__input').show(200).delay(3000).hide(400);
            //console.log($(this).data('href'));
      } else {
          selectText('__js_input' + index);
          $this.find('.__js_socials__input').show(200).delay(3000).hide(400);
      }
});
