'use strict';

var articleStartSlug = '';

(function() {

    var $W = $(window);
    var loadFlag = false;
    var toBeLoadedCount;
    if (typeof(nextArticles)!=='undefined') { toBeLoadedCount = nextArticles.length;};
    var loadedCount = 0,
        
        
    loadArticles = function () {

        var readProgressCheck = function () {

                var top = $W.scrollTop(),
                    windowHeight = $W.height(),
                    $Loader = $('<div><div>').addClass('article--loader'),
                    $loader;

                $('.__js_load_wrapper').each(function (n) {
                    
                    
                    var articleId = $(this).data('id');
                    
                    
                    if (top > $('.article--wrapper').outerHeight() - windowHeight) {
                        
                        
                        if (!loadFlag && loadedCount < toBeLoadedCount) {
                            
                            //console.log('to be loaded' + nextArticles[loadedCount] + ', ' +loadedCount+ ', ' + toBeLoadedCount);
                            
                            $.ajax({
                                url: nextArticles[loadedCount],
                                beforeSend: function () {
                                    loadFlag = true;
                                    $loader = $Loader.appendTo($('.__js_load_wrapper').last());
                                },
                                error: function(){
                                    loadFlag = false;
                                },
                                success: function (html) {
                                    
                                    //console.log('arty:' + nextArticles[loadedCount]);
                                    
                                    loadedCount +=1;
                                    //console.log('Loaded');
                                    loadFlag = false;

                                    $loader.remove();
                                    $(html)
                                        .insertAfter($('.__js_load_wrapper').last())
                                        .hide()
                                        .fadeIn(150);
                                    
                                    $sections = $('.__js_stats_wrapper');
                                    initCarouselStats();
                                    sendStats('article', 'load', initSlug);

                                }

                            });
                        }
                        else { loadFlag = true; }
                    }
                });
                setTimeout(readProgressCheck, 250);
            };
            setTimeout(readProgressCheck, 500);
        };
    
    articleStartSlug = String($('.__js_load_wrapper').eq(0).data('id'));
    articleStartSlug = articleStartSlug === "undefined" ? "" : articleStartSlug;

// debug:
//    $('<div>')
//        .css({position:"fixed", top:20, left:10,zIndex:1000,background:'#00a724',color:"#fff",padding:"1px 3px", fontSize:14, fontWeight:700})
//        .html(articleStartSlug)
//        .prependTo(document.body);
    
    
    if (/article|artykul|konkurs/.test(document.location.href)) {
        loadArticles();
    }


})(); 


