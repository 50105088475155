(function() {
    $('.__js_socialShare').live('click',function(e){
        
        e.preventDefault();
        
         var categoryName = $(this).data('category'),
             href = $(this).data('href'),
             $aID = $(this).parents('.__js_stats_wrapper').data('id'),
             cName;
        
        if($aID  == null) { $aID = 'footer';}
        
        sendStats('share-' + categoryName, 'click', $aID);
        
        if(categoryName === 'facebook') {
            cName = 'FB';
        }
        else if(categoryName === 'twitter') {
            cName = 'Twitter';
        } else {
            cName = categoryName;
        }
        WP.push(function(){
            WP.pwp.customAction({"ga": {"event": "site_interaction","eventCategory": 'share_' + cName, "moduleName":'floating share', "eventAction": deviceTouch?"tap":"click"}});
        });

        if (categoryName === 'facebook') window.open(href, '_blank', 'top=100, left=300, width=320,height=320,toolbar=1,resizable=0');
        if (categoryName === 'twitter') window.open(href, '_blank', 'top=100, left=300, width=320,height=320,toolbar=1,resizable=0');
           
    });
})();