'use strict';

$.extend($.easing, {
    easeOutExpo: function (x, t, b, c, d) {
        return (t == d) ? b + c : c * (-Math.pow(2, -10 * t / d) + 1) + b;
    },
    easeInOutExpo: function (x, t, b, c, d) {
		if (t==0) return b;
		if (t==d) return b+c;
		if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
		return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
	}
});


//Obiekt zbierający informacje o elementach typu banner, slider, before/after, których statystyki należy wysłać w unload
var statElementCollector = new Object();

//owlstats ga360 slider stats slider
var slidePositionVieved = new Object();

var initCarouselStats = function(){
    var owl = $(".owl-carousel:not([data-initialized])").each(function () {
        this.dataset.initialized = 1;
        //sprawdzamy, czy jest ustawiony id dla statystyk
        var $artID = $(this).parents('.__js_stats_wrapper').data('id');
        var ga360id = $(this).data('ga360-id');
        if (ga360id === undefined) {
            console.log('%c dodać ga360-id do slidera #' + $(this).data('id'), 'background: #553311; padding: 3px; color: #ffaaaa');
        } else {
            statElementCollector[ga360id + '-' + $artID] = {
                id: ga360id,
                moduleName: $(this).data('id'),
                viewedCount: 0,
                itemCount: $(this).find('.owl-item:not(.cloned)').length
            };
        }
        $(this).statVievedSlide = [];
        //pozycje
        $(this).find('.owl-item:not(.cloned)').each(function (n) {
            $(this).attr('idx', n + 1);
        });
        slidePositionVieved[ga360id + '-' + $artID] = {};
    });
};
initCarouselStats();


function ga360Test() {

    var statElementArray = [];

    for (var pos in statElementCollector) {
        statElementArray.push(statElementCollector[pos]);
    };

    console.log(statElementArray);

}
$(document).on('click', '.owl-next, .owl-prev', function (event) {
    
    var $owl   = $(this).parents('.owl-carousel');
    var $artID = $(this).parents('.__js_stats_wrapper').data('id');
    var $gId   = $(this).parents('.owl-carousel').data('gallery');
    var $acId  = $owl.find('.owl-item.center .owl-img>img').attr('src');
    //ga360
    var ga360id = $owl.data('ga360-id');
    var activeItem = $owl.find('.owl-item.center').attr('idx');
    slidePositionVieved[ga360id + '-' + $artID][activeItem] = 1;
    statElementCollector[ga360id + '-' + $artID]['viewedCount'] = Object.keys(slidePositionVieved[ga360id + '-' + $artID]).length;

    sendStats("gallery", "click", $artID + " / " + $gId, $acId);
    WP.push(function(){
        WP.pwp.customAction({
            "ga": {
                "event": "content_interaction",
                "eventCategory": ga360id,
                "moduleName": $gId,
                "eventAction": "click"
            }
        });
    });
});

/*
owl.on("dragged.owl.carousel", function (event) {
    var $artID = $(this).parents('.__js_stats_wrapper').data('id');
    var $gId = $(this).data('gallery');
    var ga360id = $(this).data('ga360-id');
    var $item = $('.owl-item');
    var $img = $item.find('img');
    var $acId = $img.eq(event.item.index).attr('src');
    sendStats("gallery", "swipe", "strona-glowna-sekcja-" + $artID + " / " + $gId, $acId);
    WP.push(function(){
        WP.pwp.customAction({
            "ga": {
                "event": "content_interaction",
                "eventCategory": ga360id,
                "moduleName": $gId,
                "eventAction": "swipe"
            }
        });
    });
});
*/




var scroll100Indicator = 0;
var unloadCollector = new Array(); // { id0, viewedCount0, itemCount0 }, { id1, viewedCount1, itemCount1 }  tablica do inteaktuwności sliderowych.
var forUnloadCollector = function(id, viewedCount, itemCount) {
        var objectData = new Object();
        objectData.id = id;
        objectData.viewedCount = viewedCount;
        objectData.itemCount = itemCount;
        unloadCollector.push(objectData);
    // unloadCollector push {id, viewedCount, itemCount}

};

// flaga dla urządzeń z touch:
if ('ontouchstart' in document.documentElement) {
    var deviceTouch = true;
}

// flaga dla scrollowania:
var isScrolligFlag = false;
var idTS;

window.addEventListener('scroll', function () {
    isScrolligFlag = true;
    if (idTS) {
        clearTimeout(idTS);
    }
    idTS = setTimeout(function () {
        idTS = null;
        isScrolligFlag = false;
    }, 450);
});


// unload
var indT = new Array();

var unloadData = function() {

   var ac = ac || lastReportedSlug;

   console.log('ac: ' + ac);

   if (!ac) return;

   var index = $('#'+ac).index();
   var count = 0;
   for (var i = 0; i < indT.length; ++i) {
       if(indT[i] == (index - 1))
           count++;
   }
   if (count = 4) {
       scroll100Indicator = 1;
   }
   else { scroll100Indicator = 0; };

   var statElementArray = [];

   for (var pos in statElementCollector) {
       statElementArray.push(statElementCollector[pos]);
   };

   var cScrollPercentValue = cScrollPercent.burp() || 0;

   try {
          WP.pwp.closePage({
          "ga": {
            "event": "unload",
            "page": {
                "sliderConsumption": statElementArray,
                "content": {"section": {"scroll100Indicator": scroll100Indicator}},
                "ac": ac,
                "templateType": "AS"
            },
            "content": {"pageViewCount": 1, scrollPercent: "", cScrollPercent: cScrollPercentValue } //pageViewCount ustalone s Salestube na 1 w projektach z doładowywanymi artykułami bać z wieloma artykułami na jednym widoku, scrollPercent dla danego artukułu, cScrollPercent dla całej strony
          }
        });
    }
    catch(error) {
      console.error('błąd wpjslib WP.pwp: ', error);
    }
};

window.addEventListener("beforeunload", unloadData);