'use strict';

(function() {

    var $W = $(window),
    loadFlag = false, // do ew. ograniczenia ilosci doładowań
    
    loadEdition = function () {

        var statStamp = {0: []}, //flagi, zeby wysylac tylko jeden raz staty
            readProgressCheck = function () {

                var top = $W.scrollTop(),
                    windowHeight = $W.height(),
                    $Loader = $('<div>').addClass('article_loader'),
                    $loader;

                $('.section--edition').each(function (n){

                    if (!statStamp[n]) {
                        statStamp[n] = [];
                    } //jak doczyta sie artykul ajaxem to trzeba utworzyc flagi
                    
                    if (top > $('#section--editions').outerHeight() - windowHeight) {
                          //console.log($('.article--wrapper').outerHeight());
                        if (!loadFlag && $('.section--edition').last().data('next') !== undefined) {
                            console.log('Loading: ' + $('.section--edition').last().data('next') );
                            $.ajax({
                                url: $('.section--edition').last().data('next'),
                                beforeSend: function () {
                                    loadFlag = true;
                                    $loader = $Loader.appendTo($('.section--edition').last());
                                },
                                error: function(){
                                    loadFlag = false;
                                },
                                success: function (html) {
                                    //console.log('Loaded');
                                    loadFlag = false;

                                    $loader.remove();
                                    $(html)
                                        .insertAfter($('.section--edition').last())
                                        .hide()
                                        .fadeIn(150);
                                    var lastEditionSlug = $('.section--edition').last().data('id');
                                    var category;
                                        category = $('.section--edition').last().data('id');
                                    
                                    $sections = $('.__js_stats_wrapper');
                                    sendStats(category, 'load', lastEditionSlug);
                                }

                            });
                        }
                        else { loadFlag = true; }
                    }
                });
                setTimeout(readProgressCheck, 250);
            };
            setTimeout(readProgressCheck, 500);
        };


    if ($('.section--edition').length) {
        loadEdition();
    }

})(); 


