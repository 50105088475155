
document.addEventListener('scroll', function(){
  document.querySelectorAll('.socials').forEach(function(n){
  if (n.parentNode.getBoundingClientRect().bottom>=(window.innerHeight || document. documentElement.clientHeight))
    n.style.visibility = 'visible';
    else
    n.style.visibility = 'hidden';  
  });
  
});
