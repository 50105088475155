
// menu
"use strict";

(function() {
  var MenuToggler = $(".header--hamburger").click(function(e) {
    $(this).toggleClass("__js_isActive");
    $(this).parents().find(".header").toggleClass("__js_isActive");
  });
  var $header = $(".header"); 
  $header.on("mouseleave", function() {
         $(this).parents().find(".header").removeClass("__js_isActive");
        $(".header--hamburger").removeClass("__js_isActive");
  });
  $(".header li").click(function() {
    $(this)
        .parents().find($header).removeClass("__js_isActive");
  });
})();

