
// menu
"use strict";

(function() {
  var $liderMore = $(".section-slider--more").click(function(e) {
    $(this).parents('.slider').addClass('__seeMore');
    $(this).remove();
  });
  
  
})();

