'use strict';
function sendStats(category, action, label, value) {
    if(value) {
        WP.push(function(){
            WP.stat.show(wp_sn, {"category": category, "label": label, "value": value, "asproduct": asproduct, "asorderid": asorderid, "asname": asname }, action);
        });
        gtag('event', action + '-' + value, {
            'event_category': category,
            'event_label': label,
        });
        console.log(category + ', ' +action + ", " +label + ", " + value + ", asproduct:" + asproduct + ", asorderid: " + asorderid + ", asname: " + asname);
    }
    else {
        WP.push(function(){
            WP.stat.show(wp_sn, {"category": category, "label": label, "asproduct": asproduct, "asorderid": asorderid, "asname": asname }, action);
        });
        gtag('event', action, {
            'event_category': category,
            'event_label': label
        });
        console.log(category + ', ' +action + ", " +label + ", asproduct:" + asproduct + ", asorderid: " + asorderid + ", asname: " + asname);
    }
}

Object.size = function(obj) {
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
};

$('[data-st-click]').bind('click', function(){
    
    var T = $(this).data("st-click");
    
    if (Object.size(T) && typeof(T) == 'object') {
        if(T.value !== undefined) {
            sendStats(T.category, T.action, T.label, T.value);
        }
        else {
            sendStats(T.category, T.action, T.label);
        }
    }
    else {
        console.log('%c zły format w st-click ',  'background: #f2f2f2; color: #aa2222; border:1px dashed #aa2222');
    }
});

$('[data-ga360-click]').on('click auxclick contextmenu', function(e) {

    //console.log(e.which);
    //console.log(e.type);

    if(e.type=="contextmenu"){return;}

    var T = $(this).data('ga360-click');

        switch(e.which) {
            case 1:
                //console.log('[data-ga360-click] = click');
                WP.push(function(){
                    WP.pwp.customAction({"ga": T });
                });
                break;
            case 2:
                //console.log('[data-ga360-click] = weel click');
                WP.push(function(){
                    WP.pwp.customAction({"ga": T });
                });
                break;
            case 3:
                return true;
        }
});